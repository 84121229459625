import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { GlobalPresenceContext } from '../../../../../GlobalPresence/GlobalPresenceContext';
import { personStoreFromResource } from '../../../../../People';
import { teamMemberPerson } from '../../../../../Person/types';
import { TeamMemberResource } from '../../../../../TeamsStore/Types';
import Person from '../../../../Person';
import * as style from '../../../style.module.css';

interface Props {
  teamMemberResource: TeamMemberResource;
}

const TeamMemberImpl: React.FC<Props> = ({ teamMemberResource }) => {
  const personStore = personStoreFromResource(teamMemberResource, teamMemberPerson);
  return (
    <div className={style.member} data-test-team-member={personStore.name.getOrElseValue('')}>
      <GlobalPresenceContext.Consumer>
        {(value) => <Person personStore={personStore} presenceStore={just(value)} />}
      </GlobalPresenceContext.Consumer>
    </div>
  );
};

export default observer(TeamMemberImpl);
