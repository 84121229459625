import { NavBarItem, NavBarLink } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { T } from '../../../../Translations';
import { MenuItemContent, menuItems, NavigationBarMenuItemProps } from '../Types';

function MainListMenu({ user }: NavigationBarMenuItemProps) {
  return (
    <>
      {menuItems(user.links).map((mi: MenuItemContent) => (
        <NavBarItem key={mi.rel} data-testid={`menu-bar_${mi.rel}-menu-item`}>
          <NavBarLink
            render={({ className, activeClassName, children }) => (
              <NavLink
                data-testid={`menu-bar_${mi.rel}-menu-item-link`}
                to={mi.to}
                className={className}
                activeClassName={activeClassName}
                exact={mi.exact}
              >
                {children}
              </NavLink>
            )}
          >
            <T kind={mi.content} />
          </NavBarLink>
        </NavBarItem>
      ))}
    </>
  );
}

export default observer(MainListMenu);
