import { emptyFragment } from '@execonline-inc/execonline-ui';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import { findLinkBy } from '../../../Links';
import { rootResourceStore } from '../../../RootResourceStore';
import {
  FooterCopyrightNotice,
  FooterDivider,
  PrivacyPolicyLink,
  SitemapLink,
  TermsOfUseLink,
} from '../../Footer';

function Footer() {
  return just({})
    .assign('privacyLink', findLinkBy({ rel: 'privacy' }, rootResourceStore.links))
    .assign('termsLink', findLinkBy({ rel: 'terms_and_conditions' }, rootResourceStore.links))
    .map(({ privacyLink, termsLink }) => (
      <footer
        className="mt-16 flex flex-wrap items-baseline justify-center p-2.5 opacity-70"
        data-test-footer={true}
        role="contentinfo"
      >
        <FooterCopyrightNotice />
        <FooterDivider />
        <PrivacyPolicyLink href={privacyLink.href} />
        <FooterDivider />
        <TermsOfUseLink href={termsLink.href} />
        <FooterDivider />
        <SitemapLink href="/p3/sitemap" />
      </footer>
    ))
    .getOrElse(emptyFragment);
}

export default observer(Footer);
