import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import Main from '../../../BrowserSupport/Main';
import Notifications from '../../../Notifications';
import { Link } from '../../../Resource/Types';
import AutoLaunchable from '../../AutoLaunchable';
import BoxViewer from '../../BoxViewer';
import { Footer } from '../../Footer';
import NavigationBar from '../../Platform/NavigationBar';
import * as platformStyle from '../../Platform/style.module.css';
import SkipNavLink from '../../SkipNavLink';
import Toasts from '../../Toasts';

interface Props {
  link: Link;
}

const AttachmentView: React.FC<Props> = ({ link }) => {
  return (
    <>
      <div className={platformStyle.container}>
        <SkipNavLink />
        <Toasts teamsStore={nothing()} />
        <NavigationBar />
        <Notifications />
        <AutoLaunchable />
        <Main id="main">
          <BoxViewer link={link} />
        </Main>
        <Footer />
      </div>
      <div className={platformStyle.supPanel} />
    </>
  );
};

export default observer(AttachmentView);
