import { handleEnterOrSpaceKeyPress } from '../../Accessibility/KeyboardEvents';
import { EventResource } from '../../EventsStore/Types';
import { NotTranslated } from '../../Translations';
import AlarmClockIcon from '../Cutesies/AlarmClockIcon';
import ArrowRightIcon from '../Cutesies/ArrowRightIcon';
import EventDetails from '../EventDetails';
import Now from '../EventSummary/Now';
import { useOpenable } from '../Openable';
import * as style from './style.module.css';

interface Props {
  eventResource: EventResource;
}

function CurrentEventSummary({ eventResource }: Props) {
  const { openableState, open, close } = useOpenable();
  const event = eventResource.payload;

  return (
    <>
      <div
        className={style.event}
        onClick={open}
        data-test-event-summary={event.id}
        tabIndex={0}
        onKeyPress={handleEnterOrSpaceKeyPress(open)}
      >
        <div className={style.currentEvent}>
          <div className={style.clockIcon}>
            <AlarmClockIcon />
          </div>
          <div className={style.details} data-test-event-details={true}>
            <h3 className={style.title} data-test-event-title={event.title}>
              <NotTranslated text={event.title} />
            </h3>
            <div className={style.timestamp} data-test-event-datetime={true}>
              <Now event={event} />
            </div>
          </div>
        </div>

        <div className={style.arrowIcon}>
          <ArrowRightIcon />
        </div>
      </div>
      <EventDetails modalState={openableState} close={close} event={eventResource} />
    </>
  );
}

export default CurrentEventSummary;
