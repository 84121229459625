import { Typography, TypographyProps } from '@execonline-inc/execonline-ui';
import { NotTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import { T } from '../../Translations';

function FooterCopyrightNotice(props: TypographyProps) {
  const currentYear: number = new Date().getFullYear();

  return (
    <Typography data-testid="footer_copyright-notice" variant="bodyMedium" {...props}>
      <NotTranslated text={`© ${currentYear} ExecOnline.`} /> <T kind="All rights reserved" />
    </Typography>
  );
}

export default observer(FooterCopyrightNotice);
