import { observer } from 'mobx-react';
import * as React from 'react';
import ModalStore from '../../components/Modal/Store';
import ProgramStore from '../../ProgramStore';
import { AlreadyTranslated, T } from '../../Translations';
import * as style from '../Dashboard/style.module.css';

interface Props {
  modalStore: ModalStore;
  store: ProgramStore;
}

const StreamlinedOverviewHeader: React.FC<Props> = ({ modalStore, store }) => (
  <div className={style.header}>
    <a onClick={modalStore.open}>
      {store.title
        .map((title) => (
          <h3 className={style.title} data-test-program-title={title.text}>
            <AlreadyTranslated content={title} />
          </h3>
        ))
        .getOrElse(() => (
          <></>
        ))}
      <div className={style.overviewButton}>
        <T kind="Overview" />
        <div className={style.buttonIcon}>
          <i className="fa fa-chevron-right"></i>
        </div>
      </div>
    </a>
  </div>
);

export default observer(StreamlinedOverviewHeader);
