import { emptyFragment, IconButton, Menu, useOpenable } from '@execonline-inc/execonline-ui';
import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { useRef } from 'react';
import { profileStore } from '../../../../ProfileStore';
import { useTranslationsContext } from '../../../../Translations/UseTranslationsContext';
import Person from '../../../Person';
import { NavigationMenuItems } from '../Common';
import { NavigationBarMenuItemProps } from '../Types';
import { dropdownMenuItems } from './Types';

const menuId = 'accountNavigationMenu';

function AccountMenu({ user }: NavigationBarMenuItemProps) {
  const anchorElementRef = useRef<HTMLButtonElement>(null);
  const { isOpen, onClose, onOpenChange } = useOpenable();
  const { translate } = useTranslationsContext();

  return profileStore.personStoreContract
    .map((personStore) => (
      <>
        <IconButton
          data-testid="menu-bar_account-menu-item"
          id={menuId}
          aria-haspopup="menu"
          aria-expanded={isOpen}
          ref={anchorElementRef}
          className="ml-auto"
          radius="full"
          title={translate('Account Navigation Dropdown Menu')}
          aria-label={translate('Account Navigation')}
          onClick={onOpenChange}
        >
          <Person personStore={personStore} presenceStore={nothing()} />
        </IconButton>
        <Menu
          data-testid="account-menu_dropdown"
          aria-labelledby={menuId}
          open={isOpen}
          anchorElement={anchorElementRef.current}
          placement="bottom-end"
          onClose={onClose}
        >
          <NavigationMenuItems items={dropdownMenuItems(user)} />
        </Menu>
      </>
    ))
    .getOrElse(emptyFragment);
}

export default observer(AccountMenu);
