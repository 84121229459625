import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import Main from '../../BrowserSupport/Main';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { lightTheme } from '../../Native/styles';
import NotificationSource from '../../NotificationSource';
import Notifications from '../../Notifications';
import ProgramStore from '../../ProgramStore';
import { ProgramResource } from '../../ProgramsStore/Types';
import ThemeContext from '../../Themes/ThemeContext';
import { T } from '../../Translations';
import AutoLaunchable from '../AutoLaunchable';
import { Footer } from '../Footer';
import NavigationBar from '../Platform/NavigationBar';
import * as platformStyle from '../Platform/style.module.css';
import ProgramReactions from '../ProgramReactions';
import SkipNavLink from '../SkipNavLink';
import Toasts from '../Toasts';
import ProgramDetails from './ProgramDetails';
import ProgramHeader from './ProgramHeader';
import ProgramSummaryPanel from './ProgramSummaryPanel';

interface Props {
  programStore: ProgramStore;
  programResource: ProgramResource;
}

const ProgramSummaryImpl: React.FC<Props> = ({ programStore, programResource }) => (
  <WithCurrentUser
    children={(currentUserResource) => (
      <>
        <SkipNavLink />
        <Toasts teamsStore={nothing()} />
        <div className={platformStyle.containerFull}>
          <NavigationBar />
        </div>
        <div className="flex">
          <div className={platformStyle.container}>
            <Notifications />
            <AutoLaunchable />
            <Main id="main">
              <ThemeContext.Provider value={lightTheme}>
                <ProgramHeader programResource={programResource} />
                <h2 className={platformStyle.screenReaderOnly}>
                  <T kind="Overview" />
                </h2>
                <ProgramDetails store={programStore} currentUserResource={currentUserResource} />
                <NotificationSource store={programStore} />
              </ThemeContext.Provider>
            </Main>
            <Footer />
          </div>
          <div className={platformStyle.supPanel}>
            <ProgramSummaryPanel store={programStore} />
          </div>
          <ProgramReactions store={programStore} currentUserResource={currentUserResource} />
        </div>
      </>
    )}
  />
);

export default observer(ProgramSummaryImpl);
