import { MenuItem, Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import History from '../../../../../../History';
import { T } from '../../../../../../Translations';
import { LinkListItem } from '../../../../../Dropdown/Types';
import { NavigationMenuItemProps } from '../Types';

function LinkMenuItem({ item }: NavigationMenuItemProps<LinkListItem>) {
  const { id, href, name, destination } = item;

  const handleMenuItemClick = (href: string, destination: LinkListItem['destination']) => () => {
    switch (destination) {
      case 'local':
        History.push(href);
        return;
      case 'remote':
        window.open(href, '_self', 'noopener noreferrer');
        return;
    }
  };

  return (
    <MenuItem
      data-testid={`account-menu_${id}-menu-item`}
      className="hover:text-teal-600"
      onClick={handleMenuItemClick(href, destination)}
    >
      <Typography
        data-testid={name}
        color="inherit"
        fontWeight="semibold"
        variant="bodyMedium"
        as="span"
      >
        <T kind={name} />
      </Typography>
    </MenuItem>
  );
}

export default observer(LinkMenuItem);
