import { emptyFragment, NavBarItem, NavBarLink } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../../Translations';
import { NavigationBarMenuItemProps } from '../Types';
import { supportMenu } from './Types';

function SupportMenu({ user }: NavigationBarMenuItemProps) {
  return supportMenu(user)
    .map(({ href }) => (
      <NavBarItem data-testid="menu-bar_support-menu-item">
        <NavBarLink data-testid="menu-bar_support-menu-item-link" target="_blank" href={href}>
          <T kind="Support" />
        </NavBarLink>
      </NavBarItem>
    ))
    .getOrElse(emptyFragment);
}

export default observer(SupportMenu);
