import { observer } from 'mobx-react';
import LanguageSelector from '../LanguageSelector';
import FooterDivider from './FooterDivider';

function FooterLanguageSwitcher() {
  return (
    <>
      <FooterDivider />
      <LanguageSelector separator="-" />
    </>
  );
}

export default observer(FooterLanguageSwitcher);
