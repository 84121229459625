import clsx from 'clsx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { CurrentUserResource } from '../../CurrentUser/Types';
import TermsAndConditionsStore from '../../TermsAndConditionsStore';
import * as style from '../Platform/style.module.css';
import TermsAndConditionsReactions from '../TermsAndConditionsReactions';
import TermsAndConditionsView from './TermsAndConditionsView';

interface Props {
  currentUserResource: CurrentUserResource;
}

class TermsAndConditions extends React.Component<Props> {
  store = new TermsAndConditionsStore();

  componentDidMount() {
    this.store.loading();
  }

  render() {
    return (
      <div className={clsx(style.wrapper, 'h-[100vh] w-full bg-gray-100')}>
        <TermsAndConditionsView store={this.store} />
        <TermsAndConditionsReactions
          store={this.store}
          currentUserResource={this.props.currentUserResource}
        />
      </div>
    );
  }
}

export default observer(TermsAndConditions);
