import { observer } from 'mobx-react';
import * as React from 'react';
import { BackgroundImage } from '../../../components/UI';
import { localPublicUrl } from '../../MobileImageBackground/Types';
import RegistrationViewImpl from './RegistrationViewImpl';

interface Props {
  allowLanguagePicker: boolean;
}

const RegistrationView: React.FC<Props> = ({ children, allowLanguagePicker }) => (
  <BackgroundImage src={localPublicUrl('login-bg-light.svg')}>
    <RegistrationViewImpl children={children} allowLanguagePicker={allowLanguagePicker} />
  </BackgroundImage>
);

export default observer(RegistrationView);
