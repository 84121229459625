import { Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../Translations';

interface Props {
  href: string;
}

function SitemapLink({ href }: Props) {
  return (
    <T
      kind="<link1>Sitemap</link1>."
      link1={(content) => (
        <a data-testid="footer_sitemap-link" data-test-sitemap-link={true} href={href}>
          <Typography
            data-testid="Sitemap"
            className="text-neutral-900 underline hover:text-primary-500"
            variant="bodyMedium"
            fontWeight="semibold"
            as="span"
          >
            {content}
          </Typography>
        </a>
      )}
    />
  );
}

export default observer(SitemapLink);
