import { observer } from 'mobx-react';
import * as React from 'react';
import SetCurrentUser from '../../CurrentUser/Context/SetCurrentUser';
import * as CurrentUserState from '../../CurrentUser/States';
import AuthenticatedRoutes from './AuthenticatedRoutes';

interface Props {
  state: CurrentUserState.Ready | CurrentUserState.Refreshing;
}

const AuthenticatedView: React.FC<Props> = ({ state }) => {
  const { currentUserResource } = state;

  return (
    <SetCurrentUser currentUserResource={currentUserResource}>
      <AuthenticatedRoutes currentUserResource={currentUserResource} />
    </SetCurrentUser>
  );
};

export default observer(AuthenticatedView);
