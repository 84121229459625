import { Typography } from '@execonline-inc/execonline-ui';
import { NotTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';

function FooterDivider() {
  return (
    <Typography
      data-testid="footer_divider"
      className="mx-2 leading-none text-neutral-900"
      variant="bodyMedium"
    >
      <NotTranslated text="|" />
    </Typography>
  );
}

export default observer(FooterDivider);
