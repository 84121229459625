import { IconButton } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import React from 'react';
import ThemeContext from '../../Themes/ThemeContext';
import { useTranslationsContext } from '../../Translations/UseTranslationsContext';
import CloseIcon from '../Cutesies/CloseIcon';

interface ModalHeaderPRops {
  onClose: () => void;
}
const ModalHeader: React.FC<ModalHeaderPRops> = ({ children, onClose }) => {
  const { translate } = useTranslationsContext();

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <div className="flex justify-between p-5">
          {children}
          <IconButton
            aria-label={translate('Close')}
            data-test-close-button={true}
            className="!s-[30px] !w-[30px] shrink-0 p-2 opacity-70 hover:opacity-100"
            onClick={onClose}
          >
            <CloseIcon fillVariable={theme.colors['icon-secondary-color']} />
          </IconButton>
        </div>
      )}
    </ThemeContext.Consumer>
  );
};

export default observer(ModalHeader);
