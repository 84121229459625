import { observer } from 'mobx-react';
import * as React from 'react';
import { UserLanguageStore } from '../../UserLanguageStore';
import LanguageLinks from '../LanguageSelector/LanguageLinks';
import { Separator } from '../LanguageSelector/Types';

interface Props {
  userLanguageStore: UserLanguageStore;
  separator: Separator;
}

const LoginLanguageSelectorStates: React.FC<Props> = ({ userLanguageStore, separator }) =>
  userLanguageStore.availableLanguages
    .map((languages) => (
      <LanguageLinks
        languages={languages}
        separator={separator}
        onSelect={(language) => userLanguageStore.switch(language.code)}
      />
    ))
    .getOrElse(() => <></>);

export default observer(LoginLanguageSelectorStates);
