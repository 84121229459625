import { observer } from 'mobx-react';
import * as React from 'react';
import { UpcomingProgram } from '../../ProgramStore/Types';
import { Link } from '../../Resource/Types';
import { AlreadyTranslated, NotTranslated } from '../../Translations';
import UniversityLogoOnWhite from '../Dashboard/UniversityLogoOnWhite';
import * as style from './style.module.css';

interface Props {
  programDetail: UpcomingProgram;
  links: ReadonlyArray<Link>;
}

const UpcomingProgramOverview: React.FC<Props> = ({ programDetail, links }) => (
  <div className={style.program} data-test-upcoming-program={true}>
    <div className={style.container}>
      <div className={style.content}>
        <div className={style.header}>
          <h3 className={style.title} data-test-program-title={programDetail.title.text}>
            <AlreadyTranslated content={programDetail.title} />
          </h3>
        </div>
        <p className={style.message} data-test-program-message={programDetail.message}>
          <NotTranslated text={programDetail.message} />
        </p>
      </div>
      <div className={style.logo}>
        <UniversityLogoOnWhite links={links} />
      </div>
    </div>
  </div>
);

export default observer(UpcomingProgramOverview);
