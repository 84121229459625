import { Button, Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { Language, LanguagesResource } from '../../ProfileFormStore/Types';
import { Separator } from './Types';

interface Props {
  languages: LanguagesResource;
  separator: Separator;
  onSelect: (language: Language) => void;
}

const whenSeparatorRequired = (index: number): Maybe<number> => when(index !== 0, index);

function LanguageLinks({ languages, separator, onSelect }: Props) {
  return (
    <>
      {languages.payload.map((language, index) => (
        <Fragment key={language.payload.code}>
          {whenSeparatorRequired(index)
            .map(() => (
              <Typography
                data-testid="footer_language-divider"
                className="mx-2 leading-none text-neutral-900"
                as="span"
              >
                {separator}
              </Typography>
            ))
            .getOrElse(emptyFragment)}
          <Button
            data-testid={`footer_${language.payload.englishName.toLocaleLowerCase()}-language-link`}
            className="p-0"
            variant="link"
            onClick={() => onSelect(language.payload)}
          >
            <Typography
              data-testid={language.payload.englishName}
              className="capitalize text-neutral-900 underline hover:text-primary-500"
              variant="bodyMedium"
              fontWeight="semibold"
              as="span"
            >
              {language.payload.localName}
            </Typography>
          </Button>
        </Fragment>
      ))}
    </>
  );
}

export default observer(LanguageLinks);
