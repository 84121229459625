import { observer } from 'mobx-react';
import * as React from 'react';
import { CurrentUserContext, CurrentUserLinksContext } from '.';
import { InactivityLogout } from '../../Inactivity';
import PreferredLanguageSelection from '../../PreferredLanguageSelection';
import WhenTermsAccepted from '../../components/AuthenticatedView/WhenTermsAccepted';
import IntercomTooling from '../../components/Tooling/IntercomTooling';
import { currentUserStore } from '../Store';
import { CurrentUserResource } from '../Types';
import WhenUserDataLoaded from './WhenUserDataLoaded';

interface Props {
  currentUserResource: CurrentUserResource;
}

function SetCurrentUser({ currentUserResource, children }: React.PropsWithChildren<Props>) {
  return (
    <CurrentUserContext.Provider value={currentUserResource}>
      <CurrentUserLinksContext.Provider value={currentUserResource.links}>
        <WhenUserDataLoaded
          currentUserResource={currentUserResource}
          contingencies={currentUserStore.childStores}
        >
          <PreferredLanguageSelection currentUserResource={currentUserResource}>
            <WhenTermsAccepted currentUserResource={currentUserResource}>
              <InactivityLogout />
              {children}
            </WhenTermsAccepted>
          </PreferredLanguageSelection>
        </WhenUserDataLoaded>
        <IntercomTooling currentUserResource={currentUserResource} />
      </CurrentUserLinksContext.Provider>
    </CurrentUserContext.Provider>
  );
}

export default observer(SetCurrentUser);
