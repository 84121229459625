import { Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../Translations';

interface Props {
  href: string;
}

function TermsOfUseLink({ href }: Props) {
  return (
    <T
      kind="<link1>Terms ..."
      link1={(content) => (
        <a
          data-testid="footer_test-terms-of-use-link"
          data-test-terms-of-use-link={true}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography
            data-testid="Terms of Use"
            className="text-neutral-900 underline hover:text-primary-500"
            variant="bodyMedium"
            fontWeight="semibold"
            as="span"
          >
            {content}
          </Typography>
        </a>
      )}
    />
  );
}

export default observer(TermsOfUseLink);
