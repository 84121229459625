import { observer } from 'mobx-react';
import * as React from 'react';
import { currentUserStore } from '../../CurrentUser/Store';
import { preferredLanguageSelectionStore } from '../../PreferredLanguageSelection/Store';
import LoginLanguageSelector from '../LoginLanguageSelector';
import LanguageLinks from './LanguageLinks';
import { Separator } from './Types';

interface Props {
  separator: Separator;
}

const LanguageSelector: React.FC<Props> = ({ separator }) => {
  switch (currentUserStore.state.kind) {
    case 'ready':
    case 'refreshing':
    case 'logging-out': {
      const { currentUserResource } = currentUserStore.state;
      return (
        <LanguageLinks
          languages={currentUserResource.payload.allowedLanguages}
          separator={separator}
          onSelect={(language) =>
            preferredLanguageSelectionStore.updatingPreference(language.code, currentUserResource)
          }
        />
      );
    }
    case 'waiting':
    case 'loading':
    case 'anonymous':
    case 'errored':
      return <LoginLanguageSelector separator={separator} />;
  }
};

export default observer(LanguageSelector);
