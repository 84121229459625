import * as React from 'react';
import { Link } from 'react-router-dom';
import { L, NotTranslated, T } from '../../../Translations';
import ArrowRightIcon from '../../Cutesies/ArrowRightIcon';
import UniversityLogoOnWhite from '../../Dashboard/UniversityLogoOnWhite';
import { ProgramResource } from '../Types';
import * as style from './style.module.css';

interface Props {
  programResource: ProgramResource;
}
const ProgramCard: React.FC<Props> = ({ programResource }) => {
  return (
    <div className={style.program}>
      <Link
        to={`/resources/programs/${programResource.payload.id}`}
        data-testid="program-overview-link"
      >
        <div className={style.programHeader}>
          <h2 className={style.title}>
            <NotTranslated text={programResource.payload.title} />
          </h2>
          <div className={style.logo}>
            <UniversityLogoOnWhite links={programResource.links} />
          </div>
        </div>
        <div className={style.programDetails}>
          {programResource.payload.startOn
            .map((startOn) => (
              <T
                kind="Program starts: <date/>"
                date={<L localizeable={startOn} format="long-month-day-year" />}
              />
            ))
            .getOrElseValue(<></>)}

          <div className={style.buttonSecondaryWithIcon}>
            <span>
              <T kind="View Resources" />
            </span>
            <div className={style.buttonIcon}>
              <ArrowRightIcon />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProgramCard;
