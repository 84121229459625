import { emptyFragment, Typography } from '@execonline-inc/execonline-ui';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import { findLinkBy } from '../../../Links';
import { rootResourceStore } from '../../../RootResourceStore';
import { NotTranslated, T } from '../../../Translations';
import {
  FooterCopyrightNotice,
  FooterDivider,
  FooterLanguageSwitcher,
  PrivacyPolicyLink,
  SitemapLink,
  TermsOfUseLink,
} from '../../../components/Footer';
import MailToLink from '../MailToLink';

interface Props {
  allowLanguagePicker: boolean;
}

function RegistrationFooter({ allowLanguagePicker }: Props) {
  return just({})
    .assign('privacyLink', findLinkBy({ rel: 'privacy' }, rootResourceStore.links))
    .assign('termsLink', findLinkBy({ rel: 'terms_and_conditions' }, rootResourceStore.links))
    .map(({ privacyLink, termsLink }) => (
      <footer className="mt-16 flex flex-col p-2.5 opacity-70" data-test-footer={true}>
        <div
          className="flex flex-wrap items-baseline justify-center"
          style={{ color: 'var(--body-text)' }}
        >
          <Typography variant="bodyMedium" as="span">
            <T
              kind="Having trouble accessing your account? Contact ..."
              emailAddress={(() => {
                const email = 'support@execonline.com';
                return (
                  <MailToLink email={email}>
                    <Typography className="underline" as="span">
                      <NotTranslated text={email} />
                    </Typography>
                  </MailToLink>
                );
              })()}
            />
          </Typography>
          <FooterDivider />
          <Typography variant="bodyMedium" as="span">
            <T
              kind="Use of this site constitutes acceptance of ..."
              link1={() => <TermsOfUseLink href={termsLink.href} />}
              link2={() => <PrivacyPolicyLink href={privacyLink.href} />}
            />
          </Typography>
        </div>
        <div className="flex flex-wrap items-baseline justify-center">
          <FooterCopyrightNotice />
          <FooterDivider />
          <SitemapLink href="/p3/sitemap" />
          {allowLanguagePicker && <FooterLanguageSwitcher />}
        </div>
      </footer>
    ))
    .getOrElse(emptyFragment);
}

export default observer(RegistrationFooter);
