import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import Main from '../../BrowserSupport/Main';
import Notifications from '../../Notifications';
import ResourceStore from '../../ResourceStore';
import SegmentResourceReactions from '../../SegmentResourceStore/SegmentResourceReactions';
import AutoLaunchable from '../AutoLaunchable';
import { Footer } from '../Footer';
import NavigationBar from '../Platform/NavigationBar';
import * as platformStyle from '../Platform/style.module.css';
import SkipNavLink from '../SkipNavLink';
import Toasts from '../Toasts';
import ResourceView from './ResourceView';

interface Params {
  programId: string;
  moduleId: string;
  segmentId: string;
  resourceId: string;
}

interface Props {
  match: { params: Params };
}

class SegmentResource extends React.Component<Props, {}> {
  params = this.props.match.params;
  resourceStore = new ResourceStore();

  componentDidMount() {
    this.resourceStore.loading();
  }

  render() {
    return (
      <>
        <div className={platformStyle.container}>
          <SkipNavLink />
          <Toasts teamsStore={nothing()} />
          <NavigationBar />
          <Notifications />
          <AutoLaunchable />
          <Main id="main">
            <ResourceView resourceStore={this.resourceStore} />
            <SegmentResourceReactions store={this.resourceStore} params={this.props.match.params} />
          </Main>
          <Footer />
        </div>
        <div className={platformStyle.supPanel} />
      </>
    );
  }
}

export default observer(SegmentResource);
