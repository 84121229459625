import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import Main from '../../BrowserSupport/Main';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { findLink } from '../../LinkyLinky';
import { lightTheme } from '../../Native/styles';
import NotesStore from '../../NotesStore';
import Notifications from '../../Notifications';
import NotificationSource from '../../NotificationSource';
import PdfStore from '../../PdfStore';
import PdfReactions from '../../PdfStore/PdfReactions';
import ThemeContext from '../../Themes/ThemeContext';
import I18nTitle from '../../Title/I18nTitle';
import { TPlainTextKey, translation, TranslationsContext } from '../../Translations';
import AutoLaunchable from '../AutoLaunchable';
import DownloadNotes from '../DownloadNotes';
import { Footer } from '../Footer';
import NotesReactions from '../NotesReactions';
import * as platformStyle from '../Platform/style.module.css';
import SkipNavLink from '../SkipNavLink';
import Toasts from '../Toasts';
import NoteGroups from './NoteGroups';
import PageHeader from './PageHeader';

import NavigationBar from '../Platform/NavigationBar';
import * as style from './style.module.css';

class Notes extends React.Component {
  notesStore: NotesStore = new NotesStore();
  pdfStore: PdfStore = new PdfStore();
  fileName: TPlainTextKey = 'MyNotes.pdf';

  componentDidMount() {
    this.notesStore.loading();
  }

  render() {
    return (
      <>
        <div className={platformStyle.containerFull}>
          <I18nTitle title="My Notes" />
          <SkipNavLink />
          <Toasts teamsStore={nothing()} />
          <NavigationBar />
        </div>
        <div className="flex">
          <div className="flex-1">
            <div className={platformStyle.container}>
              <Notifications />
              <AutoLaunchable />
              <Main id="main">
                <ThemeContext.Provider value={lightTheme}>
                  <PageHeader />
                  <div className={style.notes}>
                    {findLink('print', this.notesStore.links)
                      .map((l) => (
                        <TranslationsContext.Consumer>
                          {(ts) => (
                            <>
                              <DownloadNotes
                                pdfStore={this.pdfStore}
                                links={this.notesStore.links}
                                fileName={this.fileName}
                              />
                              <PdfReactions
                                store={this.pdfStore}
                                fileName={translation(this.fileName)(ts)}
                                link={l}
                                fireImmediately={true}
                              />
                            </>
                          )}
                        </TranslationsContext.Consumer>
                      ))
                      .getOrElseValue(<span />)}

                    <NoteGroups store={this.notesStore} />
                    <WithCurrentUser
                      children={(currentUserResource) => (
                        <NotesReactions
                          store={this.notesStore}
                          resourceWithNotes={{ links: currentUserResource.links.slice() }}
                        />
                      )}
                    />
                    <NotificationSource store={this.notesStore} />
                    <NotificationSource store={this.pdfStore} />
                  </div>
                </ThemeContext.Provider>
              </Main>
            </div>
          </div>
          <div className={platformStyle.supPanel}></div>
        </div>
        <Footer />
      </>
    );
  }
}

export default observer(Notes);
