import { observer } from 'mobx-react';
import * as React from 'react';
import BreakoutTimerStore from '../../BreakoutTimerStore';
import BroadcastMessageStore from '../../BroadcastMessageStore';
import Main from '../../BrowserSupport/Main';
import ChatablePersonResourcesReactions from '../../ChatablePersonResourcesReactions';
import ChatablePersonResourcesStore from '../../ChatableResourcesStore';
import { CurrentUserResource } from '../../CurrentUser/Types';
import EventsStore from '../../EventsStore';
import EventsReactions from '../../EventsStore/EventsReactions';
import { lightTheme } from '../../Native/styles';
import Notifications from '../../Notifications';
import ThemeContext from '../../Themes/ThemeContext';
import { T } from '../../Translations';
import AutoLaunchable from '../AutoLaunchable';
import { Footer } from '../Footer';
import NavigationBar from '../Platform/NavigationBar';
import * as platformStyle from '../Platform/style.module.css';
import SkipNavLink from '../SkipNavLink';
import Dashboard from './Dashboard';
import * as style from './style.module.css';

interface Params {
  eventId: string;
}

interface Props {
  match: { params: Params };
  currentUserResource: CurrentUserResource;
}

class EventDashboard extends React.Component<Props, {}> {
  params = this.props.match.params;
  eventsStore = new EventsStore(this.props.currentUserResource);
  broadcastMessageStore = new BroadcastMessageStore();
  timerStore = new BreakoutTimerStore();
  chatablePersonResourcesStore = new ChatablePersonResourcesStore();

  render() {
    return (
      <>
        <SkipNavLink />
        <div className={style.smallNavPadding}>
          <NavigationBar />
        </div>
        <div className="flex">
          <div className={style.dashboardContainer}>
            <Notifications />
            <AutoLaunchable />
            <Main id="main">
              <ThemeContext.Provider value={lightTheme}>
                <h1
                  className={platformStyle.pageTitle}
                  data-test-event-dashboard-title="Live Event Dashboard"
                >
                  <T kind="Live Event Dashboard" />
                </h1>
                <EventsReactions store={this.eventsStore} fireImmediately={true} />
                <ChatablePersonResourcesReactions store={this.chatablePersonResourcesStore} />
                <Dashboard
                  eventsStore={this.eventsStore}
                  eventId={this.params.eventId}
                  broadcastMessageStore={this.broadcastMessageStore}
                  timerStore={this.timerStore}
                  chatablePersonResourcesStore={this.chatablePersonResourcesStore}
                />
              </ThemeContext.Provider>
            </Main>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}

export default observer(EventDashboard);
