import {
  AppsIcon,
  IconButton,
  Menu,
  MenuSection,
  useMediaQuery,
  useOpenable,
} from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { useRef } from 'react';
import { T } from '../../../../Translations';
import { useTranslationsContext } from '../../../../Translations/UseTranslationsContext';
import { NavigationMenuItems } from '../Common';
import { NavigationBarMenuItemProps } from '../Types';
import { dropdownMenuItems, getIconSize } from './Types';

const menuId = 'applicationNavigationMenu';

function ApplicationDropdownMenu({ user }: NavigationBarMenuItemProps) {
  const anchorElementRef = useRef<HTMLButtonElement>(null);
  const { isOpen, onClose, onOpenChange } = useOpenable();
  const breakPoint = useMediaQuery();
  const { translate } = useTranslationsContext();

  return (
    <>
      <IconButton
        data-testid="menu-bar_application-menu-item"
        id={menuId}
        aria-haspopup="menu"
        aria-expanded={isOpen}
        ref={anchorElementRef}
        className="mr-4"
        title={translate('Platform Navigation Dropdown Menu')}
        aria-label={translate('Platform Navigation Dropdown Menu')}
        onClick={onOpenChange}
      >
        <AppsIcon size={getIconSize(breakPoint)} color="black" />
      </IconButton>
      <Menu
        data-testid="application-menu_dropdown"
        aria-labelledby={menuId}
        open={isOpen}
        anchorElement={anchorElementRef.current}
        onClose={onClose}
      >
        <MenuSection title={<T kind="Switch to" />}>
          <NavigationMenuItems items={dropdownMenuItems(user)} />
        </MenuSection>
      </Menu>
    </>
  );
}

export default observer(ApplicationDropdownMenu);
