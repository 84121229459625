import { IconButton } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import React from 'react';
import CloseIcon from '../../../../components/Cutesies/CloseIcon';
import DeliveryInfoIcon from '../../../../components/Cutesies/DeliveryInfoIcon';
import { TranslationsContext, translation } from '../../../../Translations';
import { ExperienceResource } from '../../Common/Experience/Types';
import Store from './Store';

interface Props {
  experienceResource: ExperienceResource;
  store: Store;
  children: React.ReactNode;
}

const LmsContent: React.FC<Props> = ({ store, experienceResource, children }) => (
  <div
    style={{ backgroundColor: experienceResource.payload.secondaryColor }}
    className="mx-auto xl:container"
  >
    <div className="flex items-center gap-x-2 px-7 py-6">
      <div className="flex h-5 w-5 flex-shrink-0">
        <DeliveryInfoIcon />
      </div>
      <div>{children}</div>
      <div>
        <TranslationsContext.Consumer>
          {(ts) => (
            <IconButton
              aria-label={translation('Close')(ts)}
              className="!h-3.5 !w-3.5"
              onClick={store.hiding}
            >
              <CloseIcon />
            </IconButton>
          )}
        </TranslationsContext.Consumer>
      </div>
    </div>
  </div>
);

export default observer(LmsContent);
