import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import GlobalPresenceMembersStore from '../../../../GlobalPresence/GlobalPresenceStore';
import { personStoreFromResource } from '../../../../People';
import { teamMemberPerson } from '../../../../Person/types';
import { TeamMemberResource } from '../../../../TeamsSummaryStore/Types';
import Person from '../../../Person';
import * as style from '../style.module.css';

interface Props {
  teamMemberResource: TeamMemberResource;
  globalPresence: Maybe<GlobalPresenceMembersStore>;
}

const TeamMemberImpl: React.FC<Props> = ({ teamMemberResource, globalPresence }) => {
  const personStore = personStoreFromResource(teamMemberResource, teamMemberPerson);
  return (
    <div className={style.member} data-test-team-member={personStore.name.getOrElseValue('')}>
      <Person personStore={personStore} presenceStore={globalPresence} />
    </div>
  );
};

export default observer(TeamMemberImpl);
