import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import Main from '../../BrowserSupport/Main';
import Notifications from '../../Notifications';
import { Link } from '../../Resource/Types';
import AutoLaunchable from '../AutoLaunchable';
import BoxViewer from '../BoxViewer';
import { Footer } from '../Footer';
import NavigationBar from '../Platform/NavigationBar';
import * as platformStyle from '../Platform/style.module.css';
import SkipNavLink from '../SkipNavLink';
import Toasts from '../Toasts';

interface Params {
  assignmentId: string;
  attachmentId: string;
}

interface Props {
  match: { params: Params };
}

class ViewableAssignment extends React.Component<Props> {
  assignmentId = this.props.match.params.assignmentId;
  attachmentId = this.props.match.params.attachmentId;

  assignmentLink = (assignmentId: string, attachmentId: string): Link => ({
    href: `${process.env.REACT_APP_API_ROOT}/assignments/${assignmentId}/viewable_assignments/${attachmentId}.json`,
    method: 'get',
    rel: 'self',
    type: 'application/json',
  });

  render() {
    return (
      <>
        <div className={platformStyle.container}>
          <SkipNavLink />
          <Toasts teamsStore={nothing()} />
          <NavigationBar />
          <Notifications />
          <AutoLaunchable />
          <Main id="main">
            <BoxViewer link={this.assignmentLink(this.assignmentId, this.attachmentId)} />
          </Main>
          <Footer />
        </div>
        <div className={platformStyle.supPanel} />
      </>
    );
  }
}

export default observer(ViewableAssignment);
